import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/auth.action';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { AuthService } from '../../services';
import { Store } from '@ngrx/store';
import { getRouterState, Go } from '../../../store/index';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

import { nl, enUS } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { CmsUser } from '../../models';

export const checkAuth$ = createEffect(
  (actions$ = inject(Actions), service = inject(AuthService)) => {
    return actions$.pipe(
      ofType(actions.CheckAuth),
      switchMap(() =>
        service.checkAuth().pipe(
          map(user => actions.CheckAuthSuccess({ user })),
          catchError(error => of(actions.CheckAuthFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const checkAuthSuccess$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.CheckAuthSuccess),
      tap(({ user }) => {
        setLocalStorageLocale(user);
      })
    );
  },
  { functional: true, dispatch: false }
);

export const signIn$ = createEffect(
  (actions$ = inject(Actions), service = inject(AuthService)) => {
    return actions$.pipe(
      ofType(actions.SignIn),
      switchMap(({ loginData }) =>
        service.signIn(loginData).pipe(
          map(user => {
            return actions.SignInSuccess({ user });
          }),
          catchError(error => {
            return of(actions.SignInFail({ error }));
          })
        )
      )
    );
  },
  { functional: true }
);

export const signInFail$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.SignInFail),
      tap(({ error }) => {
        if (error?.error?.requiresTwoFactor) {
          store.dispatch(Go({ path: ['/', 'auth', 'login', '2fa'] }));
        }
        return null;
      })
    );
  },
  { functional: true, dispatch: false }
);

export const signInSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.SignInSuccess),
      tap(({ user }) => {
        sb.open(`Ingelogd als ${user?.fullName}`, '', { duration: 5000 });
        setLocalStorageLocale(user);
        window.location.href = '/';
      }),
      switchMap(() =>
        store
          .select(getRouterState)
          .pipe(take(1))
          .pipe(
            map(({ state }) => {
              return Go({ path: [state.queryParams['returnUrl'] || '/'] });
            })
          )
      )
    );
  },
  { functional: true }
);

export const switchTenant$ = createEffect(
  (actions$ = inject(Actions), service = inject(AuthService)) => {
    return actions$.pipe(
      ofType(actions.SwitchTenant),
      switchMap(({ tenantId }) =>
        service.switchTenant(tenantId).pipe(
          map(user => actions.SwitchTenantSuccess({ user })),
          catchError(error => of(actions.SwitchTenantFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const switchTenantSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar)) => {
    return actions$.pipe(
      ofType(actions.SwitchTenantSuccess),
      tap(({ user }) => {
        setLocalStorageLocale(user);
        sb.open(`Switched to ${user?.selectedTenant.name}`, '', { duration: 5000 });
        window.location.href = '/';
      }),
      map(() => Go({ path: ['/'] }))
    );
  },
  { functional: true }
);

export const signOut$ = createEffect(
  (actions$ = inject(Actions), service = inject(AuthService)) => {
    return actions$.pipe(
      ofType(actions.SignOut),
      switchMap(() =>
        service.SignOut().pipe(
          map(auth => actions.SignOutSuccess({ result: auth })),
          catchError(error => of(actions.SignOutFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const signOutSuccess$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.SignOutSuccess),
      tap(() => (window.location.href = ''))
    );
  },
  { dispatch: false, functional: true }
);

export const register$ = createEffect(
  (actions$ = inject(Actions), service = inject(AuthService)) => {
    return actions$.pipe(
      ofType(actions.Register),
      switchMap(({ registerData }) =>
        service.register(registerData).pipe(
          map(result => actions.RegisterSuccess({ result: result })),
          catchError(error => of(actions.RegisterFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const signIn2FA$ = createEffect(
  (actions$ = inject(Actions), service = inject(AuthService)) => {
    return actions$.pipe(
      ofType(actions.SignIn2FA),
      switchMap(({ otp, isPersistent }) =>
        service.otpSignIn(otp, isPersistent).pipe(
          map(user => {
            return actions.SignInSuccess({ user });
          }),
          catchError(error => {
            return of(actions.SignInFail({ error }));
          })
        )
      )
    );
  },
  { functional: true }
);

export const updateProfile$ = createEffect(
  (actions$ = inject(Actions), service = inject(AuthService)) => {
    return actions$.pipe(
      ofType(actions.UpdateAccount),
      switchMap(({ data }) =>
        service.update(data).pipe(
          map(result => actions.UpdateAccountSuccess({ profile: result })),
          catchError(error => of(actions.UpdateAccountFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const updateProfileSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), lang = inject(LanguageService)) => {
    const dict = new FromDictionaryPipe(lang);
    return actions$.pipe(
      ofType(actions.UpdateAccountSuccess),
      tap(({ profile }) => {
        setLocalStorageLocale(profile);
        sb.open(dict.transform('auth:profile-saved'), 'OK', { duration: 2000 });
      })
    );
  },
  { functional: true, dispatch: false }
);

const setLocalStorageLocale = (user: CmsUser | undefined) => {
  if (user?.languageId === 2) {
    localStorage.setItem('CMSv5:LOCALE', 'en');
  } else {
    localStorage.setItem('CMSv5:LOCALE', 'nl');
  }
};
